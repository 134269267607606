/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import WorldMap from "../../components/WorldMap";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Table,
    Row,
    Col,
} from "reactstrap";

import { useSelector } from "react-redux";
import { AppState } from "../../../store/store";

import ApiCaller from "../../../lib/ApiCaller";
import UserApi from "../../../api/UserApi";

import { ToastContainer, toast } from 'react-toastify';
import StatsCardsContainer from "../../../components/StatsCardsContainer/StatsCardsContainer";
import {formatDateTimeConversational} from "../../../shared/utils/dateTime";
import { DEFAULT_DASHBOARD_STATS } from "../../../shared/constants/defaultDashboardStats";

import 'react-toastify/dist/ReactToastify.css';

import ExamPaymentsReport from '../../components/dashboard/examPayments/ExamPaymentsReport';

var mapData = {
    AU: 760,
    BR: 550,
    CA: 120,
    DE: 1300,
    FR: 540,
    GB: 690,
    GE: 200,
    IN: 200,
    RO: 600,
    RU: 300,
    US: 2920,
};

interface ContainerProps {
}

const Dashboard:React.FC<ContainerProps> = ({ }) =>  {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [data, setData] = React.useState(DEFAULT_DASHBOARD_STATS);

    React.useEffect(() => {
        if (!loggedUser?.isEmpty()) {
            var userApi = new UserApi(new ApiCaller());
            userApi.getMyStats(loggedUser.token).then((data: any) => {
                if (data) {
                    setData(data);
                }
            }).catch((err) => {
                console.error(err);
                toast.error('Error retrieving data?.');
            })
        }
    }, []);

    const getGaugeData = (info: any, primaryColor: string, secondaryColor?: string): any => {
        return {
            labels: [1, 2],
            datasets: [
                {
                    label: info?.label,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    backgroundColor: [primaryColor, secondaryColor || "#f4f3ef"],
                    borderWidth: 0,
                    barPercentage: 1.6,
                    data: [info?.value, (100 - info?.value)],
                },
            ],
        }
    }

    const getGaugeOptions = (info: any): any => {
        const data =  {
            elements: {
                center: {
                    text: `${info.value}%`,
                    color: "#66615c", // Default is #000000
                    fontStyle: "Arial", // Default is Arial
                    sidePadding: 60, // Defualt is 20 (as a percentage)
                },
            },
            cutoutPercentage: 90,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            display: false,
                        },
                        gridLines: {
                            drawBorder: false,
                            zeroLineColor: "transparent",
                            color: "rgba(255,255,255,0.05)",
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            color: "rgba(255,255,255,0.1)",
                            zeroLineColor: "transparent",
                        },
                        ticks: {
                            display: false,
                        },
                    },
                ],
            },
        };

        return data
    }

    const getChartData = (info:any, color: string): any => {
        const data = {
            labels: info.data?.x,
            datasets: [
                {
                    label: info.data?.label,
                    borderColor: color,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 3,
                    barPercentage: 1.6,
                    data: info.data?.y,
                },
            ],
        };

        return data;
    }

    const getChartOptions = (): any => {
        const data = {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            fontColor: "#9f9f9f",
                            beginAtZero: false,
                            maxTicksLimit: 5,
                        },
                        gridLines: {
                            drawBorder: false,
                            zeroLineColor: "transparent",
                            color: "rgba(255,255,255,0.05)",
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            color: "rgba(255,255,255,0.1)",
                            zeroLineColor: "transparent",
                            display: false,
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "#9f9f9f",
                        },
                    },
                ],
            },
        }

        return data;
    }

    return (
        <>
            <div className="content">
                <Row>
                  <StatsCardsContainer data={data.numerical} />
                </Row>
                <Row>
                    { data?.chart[0] &&
                    <Col lg="4" sm="6">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="7">
                                        <div className="numbers pull-left">{ data?.chart[0].value }</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right">
                                            <Badge color="success" pill>
                                                {`${data?.chart[0].badge}%`}
                                            </Badge>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="big-title">
                                    { data?.chart[0].title }
                                </h6>
                                <Line
                                    data={ getChartData(data?.chart[0], "#6bd098") }
                                    options={ getChartOptions() }
                                    height={380}
                                    width={826}
                                />
                            </CardBody>
                            <CardFooter>
                                <hr/>
                                <Row>
                                    <Col sm="7">
                                        <div className="footer-title">{ data?.chart[0].footer }</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right">
                                            <Button
                                                className="btn-round btn-icon"
                                                color="success"
                                                size="sm"
                                            >
                                                <i className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                    { data?.chart[1] &&
                    <Col lg="4" sm="6">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="7">
                                        <div className="numbers pull-left">{ data?.chart[1].value }</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right">
                                            <Badge color="danger" pill>
                                                {`${data?.chart[1].badge}%`}
                                            </Badge>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="big-title">
                                    { data?.chart[1].title }
                                </h6>
                                <Line
                                    data={ getChartData(data?.chart[1], "#ef8156") }
                                    options={ getChartOptions() }
                                    height={380}
                                    width={828}
                                />
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <Row>
                                    <Col sm="7">
                                        <div className="footer-title">{ data?.chart[1].footer }</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right">
                                            <Button
                                                className="btn-round btn-icon"
                                                color="danger"
                                                size="sm"
                                            >
                                                <i className="nc-icon nc-button-play" />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                    { data?.chart[2] &&
                    <Col lg="4" sm="6">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="7">
                                        <div className="numbers pull-left">{ data?.chart[2].value }</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right">
                                            <Badge color="warning" pill>
                                                { `${data?.chart[2].badge}%` }
                                            </Badge>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="big-title">{ data?.chart[2].title }</h6>
                                <Line
                                    data={ getChartData(data?.chart[2], "#2CA8FF")}
                                    options={ getChartOptions() }
                                    height={380}
                                    width={826}
                                />
                            </CardBody>
                            <CardFooter>
                                <hr/>
                                <Row>
                                    <Col sm="7">
                                        <div className="footer-title">{ data?.chart[2].footer }</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right">
                                            <Button
                                                className="btn-round btn-icon"
                                                color="warning"
                                                size="sm"
                                            >
                                                <i className="nc-icon nc-alert-circle-i"/>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                </Row>
                {data?.table[0] && data?.table[0].rows &&
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{data?.table[0].title}</CardTitle>
                                <p className="card-category">
                                    {data?.table[0].subtitle}
                                </p>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <Table responsive>
                                            <tbody>
                                            { data?.table[0].rows.map((row) => {
                                                return(
                                                    <tr>
                                                        <td>
                                                            <div className="flag">
                                                                <img
                                                                    alt="..."
                                                                    // src={require(`${row.icon}`)}
                                                                    src={require(`assets/img/flags/${row.icon}.png`)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{ row.location }</td>
                                                        {
                                                            row.values.map((value, index) => {
                                                                return (<td className="text-right">{ value }</td>)
                                                            })
                                                        }
                                                    </tr>
                                                );
                                            })}

                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col md="6">
                                        { data?.table[0] &&
                                        <WorldMap markers={data?.table[0].rows}/>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                }
                <Row>
                    {data?.gauge[0] &&
                    <Col md="3">
                        <Card>
                            <CardHeader>
                                <CardTitle>{ data?.gauge[0].title }</CardTitle>
                                <p className="card-category">{ data?.gauge[0].subtitle }</p>
                            </CardHeader>
                            <CardBody>
                                <Doughnut
                                    data={getGaugeData(data?.gauge[0], "#fcc468")}
                                    options={getGaugeOptions(data?.gauge[0])}
                                    // className="ct-chart ct-perfect-fourth"
                                    height={300}
                                    width={456}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-info"/>
                                    Pending pending
                                </div>
                                <hr/>
                                <div className="stats">
                                    <i className="fa fa-calendar"/>
                                    Updated Now
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                    { data?.gauge[1] &&
                    <Col md="3">
                        <Card>
                            <CardHeader>
                                <CardTitle>{ data?.gauge[1].title }</CardTitle>
                                <p className="card-category">{ data?.gauge[1].subtitle }</p>
                            </CardHeader>
                            <CardBody>
                                <Doughnut
                                    data={getGaugeData(data?.gauge[1], "#f17e5d")}
                                    options={getGaugeOptions(data?.gauge[1])}
                                    // className="ct-chart ct-perfect-fourth"
                                    height={300}
                                    width={456}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-warning"/>
                                    Cancelled
                                </div>
                                <hr/>
                                <div className="stats">
                                    <i className="fa fa-check"/>
                                    Updated yesterday
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                    {data?.gauge[2] &&
                    <Col md="3">
                        <Card>
                            <CardHeader>
                                <CardTitle>{ data?.gauge[2].title }</CardTitle>
                                <p className="card-category">{ data?.gauge[2].subtitle }</p>
                            </CardHeader>
                            <CardBody>
                                <Doughnut
                                    data={getGaugeData(data?.gauge[2], "#66615b")}
                                    options={getGaugeOptions(data?.gauge[2])}
                                    // className="ct-chart ct-perfect-fourth"
                                    height={300}
                                    width={456}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-danger"/>
                                    Passed
                                </div>
                                <hr/>
                                <div className="stats">
                                    <i className="fa fa-clock-o"/>
                                    Updated this week
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                    { data?.gauge[3] &&
                    <Col md="3">
                        <Card>
                            <CardHeader>
                                <CardTitle>{ data?.gauge[3].title }</CardTitle>
                                <p className="card-category">{ data?.gauge[3].subtitle }</p>
                            </CardHeader>
                            <CardBody>
                                <Doughnut
                                    data={getGaugeData(data?.gauge[3], "#4acccd")}
                                    options={getGaugeOptions(data?.gauge[3])}
                                    // className="ct-chart ct-perfect-fourth"
                                    height={300}
                                    width={456}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-secondary"/>
                                    Passed
                                </div>
                                <hr/>
                                <div className="stats">
                                    <i className="fa fa-history"/>
                                    Updated this week
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <ExamPaymentsReport loggedUser={loggedUser} />
                    </Col>
                </Row>
            </div>
            <ToastContainer/>
        </>
    );
}

export default Dashboard;

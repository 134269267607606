import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  Button,
  Col,
  Row,
  FormGroup,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Modal,
  Input,
} from 'reactstrap';

import ApiCaller from '../../../../lib/ApiCaller';
import CandidateApi from '../../../../api/CandidateApi';
import CandidateCollection from '../../../../lib/common/models/candidateCollection';
import Candidate from '../../../../lib/common/models/candidate';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { AppState } from '../../../../store/store';

interface ModalProps {
  modal: boolean;
  setModal: Function;
  candidates: CandidateCollection;
  setCandidates: Function;
  candidateForm: any;
  setCandidateForm: Function;
}

const CandidateModal: React.FC<ModalProps> = ({
  modal,
  setModal,
  candidates,
  setCandidates,
  candidateForm,
  setCandidateForm,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [alert, setAlert] = useState<boolean>(false);
  const [error, setError] = useState('');

  const resetForm = () => {
    setCandidateForm(Candidate.prototype.emptyCandidate);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const info = candidateForm?.toObject();
    if (info) {
      info[name] = value;
      setCandidateForm(new Candidate(info));
    }
  };

  const handleToggle = (e: any) => {
    const info = candidateForm?.toObject();
    if (info) {
      info['special'] = e.target.checked;
      setCandidateForm(new Candidate(info));
    }
  };

  const onDateChange = (e: any) => {
    const info = candidateForm?.toObject();

    if (info) {
      info['birthDate'] = e.toDate();
      setCandidateForm(new Candidate(info));
    }
  };

  const updateCandidate = () => {
    const candidateApi = new CandidateApi(new ApiCaller());
    candidateApi
      .updateCandidate(candidateForm, loggedUser.token)
      .then((data: Candidate) => {
        const updatedCandidates = candidates.candidates.map((item) => {
          return item._id === data._id ? data : item;
        });
        setCandidates(new CandidateCollection(updatedCandidates));
        resetForm();
        setModal(false);
      })
      .catch(() => {
        toast.error('Error creating candidate');
      });
  };

  const candidateCreateSubmit = () => {
    return checkDuplicates() ? setAlert(true) : createCandidate();
  };

  const checkDuplicates = () => {
    const checks = candidates.candidates.map((candidate) => {
      return Candidate.prototype.checkDuplicate(candidate, candidateForm);
    });

    return checks.indexOf(true) !== -1;
  };

  const createCandidate = () => {
    setAlert(false);
    if (candidateForm) {
      const candidateApi = new CandidateApi(new ApiCaller());
      candidateApi
        .createCandidate(candidateForm, loggedUser.token)
        .then((data: Candidate) => {
          setCandidates(
            new CandidateCollection([...candidates.candidates, data]),
          );
          resetForm();
          setModal(false);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  };

  const candidateCreateConfirm = () => {
    setAlert(false);
    createCandidate();
  };

  const closeModal = () => {
    resetForm();
    setModal(false);
  };

  return (
    <Modal isOpen={modal}>
      <ModalHeader toggle={() => setModal(false)}>Create candidate</ModalHeader>
      <ModalBody>
        <Form className="form-horizontal">
          <Row>
            <Label sm="3">First Name</Label>
            <Col sm="9">
              <FormGroup>
                <Input
                  value={candidateForm?.firstName}
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="3">Last Name</Label>
            <Col sm="9">
              <FormGroup>
                <Input
                  value={candidateForm?.lastName}
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="3">Birthday</Label>
            <Col sm="9">
              <FormGroup>
                <ReactDatetime
                  inputProps={{
                    className: 'form-control',
                    placeholder: 'Pick Date',
                    name: 'Date of birth',
                    readOnly: true,
                  }}
                  timeFormat={false}
                  dateFormat={'DD-MM-YYYY'}
                  onChange={(e: any) => onDateChange(e)}
                  value={moment(candidateForm?.birthDate)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="3">Passport No.</Label>
            <Col sm="9">
              <FormGroup>
                <Input
                  value={candidateForm?.passportNumber}
                  type="text"
                  name="passportNumber"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="3">Email</Label>
            <Col sm="9">
              <FormGroup>
                <Input
                  value={candidateForm?.email}
                  type="email"
                  name="email"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="3">Learning Diff.</Label>
            <Col sm="9">
              <FormGroup>
                <Input
                  checked={candidateForm?.special}
                  type="checkbox"
                  name="special"
                  onChange={handleToggle}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Button
              color="primary"
              onClick={
                candidateForm._id ? updateCandidate : candidateCreateSubmit
              }>
              Save
            </Button>
            <Button color="danger" onClick={closeModal}>
              Cancel
            </Button>
          </Row>
        </Form>
      </ModalBody>
      {alert && (
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Duplicate alert"
          onConfirm={() => candidateCreateConfirm()}
          onCancel={() => setAlert(false)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Create"
          cancelBtnText="Cancel"
          showCancel
          btnSize="">
          Similar candidate/s found in the system. Do you want to proceed?
        </ReactBSAlert>
      )}
      {error && (
        <ReactBSAlert
          error
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error creating candidate"
          onConfirm={() => setError('')}
          confirmBtnBsStyle="danger"
          confirmBtnText="Close"
          btnSize="">
          {error}
        </ReactBSAlert>
      )}
    </Modal>
  );
};

export default CandidateModal;

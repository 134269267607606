import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  Table,
  Input,
} from 'reactstrap';
import Badge from 'react-bootstrap/Badge';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { AppState } from '../../../store/store';

import ApiCaller from '../../../lib/ApiCaller';
import NavigationExam from '../../../lib/common/models/navigationExam';
import { PaymentTypeLabels } from '../../../lib/common/models/transaction';
import ReactTable from '../../../components/ReactTable/ReactTable';
import NavigationExamApi from '../../../api/NavigationExamApi';
import {
  downloadCertificate,
  downloadPreviewCertificate,
} from '../../../shared/utils/ApiCommands';
import CertificateApi from '../../../api/CertificateApi';
import ExamGrade from '../../../lib/common/models/examGrade';
import ExamGradeCollection from '../../../lib/common/models/examGradeCollection';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import NavigationExamPayComponent from 'views/components/payment/navigationExamPayComponent';
import NotificationApi from 'api/NotificationApi';
import Marks from './Marks';

type CandidateResultsProps = {
  currentExam: NavigationExam;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setLoadingTip: (tip: string) => void;
};

const CandidateResults: FC<CandidateResultsProps> = ({
  currentExam,
  loading,
  setLoading,
  setLoadingTip,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [isSendCertificateModalOpen, setIsSendCertificateModalOpen] =
    useState(false);
  const [certificatesToSend, setCertificatesToSend] = useState<any[]>([]);
  const [availableCertificatesToSend, setAvailableCertificatesToSend] =
    useState<ExamGrade[]>([]);
  const [isSelectCertificatesMode, setIsSelectCertificatesMode] =
    useState<boolean>(false);
  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [currentCell, setCurrentCell] = useState<any>({});
  const [examGrades, setExamGrades] = React.useState<ExamGradeCollection>(
    new ExamGradeCollection([]),
  );
  const [marksByCandidate, setMarksByCandidate] = useState<any>(null);

  const updateGrades = () => {
    if (currentExam?.id) {
      const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
      examApi.getCandidatesResults(currentExam.id).then((data: any) => {
        setExamGrades(data);
        setAvailableCertificatesToSend(
          data.grades.filter(
            (grade: ExamGrade) =>
              grade.certificateId && !grade.isCertificateSent,
          ),
        );
      });
    }
  };

  React.useEffect(() => {
    updateGrades();
  }, [currentExam.id]);

  const generate = useCallback(() => {
    setIsCertificateModalOpen(false);
    setLoadingTip('Generating certificate...');
    setLoading(true);
    const selectedGrade: ExamGrade = currentCell.row.original;
    const certificateApi = new CertificateApi(new ApiCaller());
    certificateApi
      .generateCertificate(selectedGrade._id!, loggedUser.token)
      .then(() => {
        updateGrades();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Failed to generate certificate');
      });
  }, [currentCell]);

  const reissueCertificate = useCallback(() => {
    setModalPayment(false);
    setLoadingTip('Reissuing certificate...');
    setLoading(true);
    const selectedGrade: ExamGrade = currentCell.row.original;
    const certificateApi = new CertificateApi(new ApiCaller());
    certificateApi
      .reissue(selectedGrade.certificateId!, loggedUser.token)
      .then(() => {
        updateGrades();
        setLoading(false);
        toast.success('Certificate has been reissued');
      })
      .catch(() => {
        setLoading(false);
        toast.error(
          'Failed to reissue certificate. Payment has been reverted.',
        );
      });
  }, [currentCell, loggedUser.token]);

  const sendCertificates = (certificates: string[]) => {
    setIsSendCertificateModalOpen(false);
    setIsSelectCertificatesMode(false);
    const loadingTip =
      certificates.length > 1
        ? 'Sending certificates...'
        : 'Sending certificate...';
    setLoadingTip(loadingTip);
    setLoading(true);
    const notificationApi = new NotificationApi(new ApiCaller());
    notificationApi
      .sendCertificates(certificates, loggedUser.token)
      .then(() => {
        setLoading(false);
        toast.success('Certificate has been sent to candidate');
        updateGrades();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Failed to send certificate');
      });
  };

  const getResult = (candidateId: string) => {
    const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
    if (currentExam.id) {
      examApi
        .getCandidateResult(currentExam.id, candidateId)
        .then((data: any) => {
          setMarksByCandidate(data);
        });
    }
  };

  const displayResult = (
    result: string,
    grade?: number,
    details?: any,
  ): string => {
    if (result == 'MARGINAL') {
      result = 'BORDERLINE';
      grade = undefined;
    }
    let examGrade = grade ? `${result}(${grade})` : result;
    if (details) {
      examGrade = examGrade + ' / ';
      for (let key in details) {
        examGrade =
          examGrade + `${key}: ${details[key].result}(${details[key].grade}), `;
      }
      examGrade = examGrade.substring(0, examGrade.length - 2);
    }
    return examGrade;
  };

  const getCertificateBage = (examGrade: ExamGrade) => {
    if (examGrade.isCertificateEligible()) {
      if (examGrade.certificateId && examGrade.isCertificateSent) {
        return (
          <>
            <Badge variant="success">Generated</Badge>
            <Badge variant="success">Sent</Badge>
          </>
        );
      } else if (examGrade.certificateId && !examGrade.isCertificateSent) {
        return (
          <>
            <Badge variant="success">Generated</Badge>
            <Badge variant="warning">Not sent yet</Badge>
          </>
        );
      } else {
        return <Badge variant="warning">Not generated</Badge>;
      }
    } else {
      return <Badge variant="danger">Not available</Badge>;
    }
  };

  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) =>
        d.candidateId.serialNumber ? d.candidateId.serialNumber : '-',
      sortable: true,
    },
    {
      Header: 'NAME',
      accessor: (d: any) =>
        `${d.candidateId.firstName} ${d.candidateId.lastName}`,
      sortable: false,
    },
    {
      Header: 'BIRTH DATE',
      accessor: (d: any) =>
        moment(d.candidateId.birthDate).utc().format('DD-MM-YYYY'),
    },
    {
      Header: 'RESULT',
      Cell: (cell: any) =>
        displayResult(
          cell.row.original.result,
          cell.row.original.grade,
          cell.row.original.details,
        ),
    },
    {
      Header: 'CERTIFICATE',
      Cell: (cell: any) => getCertificateBage(cell.row.original),
    },
    {
      Header: 'ACTIONS',
      Cell: (cell: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          {cell.row.original.certificateId && (
            <>
              <Dropdown.Item
                onClick={() => {
                  setCurrentCell(cell);
                  setModalPayment(true);
                }}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;REISSUE CERTIFICATE
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  downloadCertificate(
                    cell.row.original.certificateId,
                    loggedUser.token,
                  )
                }>
                <i className="nc-icon nc-cloud-download-93" />
                &nbsp;&nbsp;DOWNLOAD CERTIFICATE
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  sendCertificates([cell.row.original.certificateId]);
                }}
                disabled={cell.row.original.isCertificateSent}>
                <i className="nc-icon nc-email-85" />
                &nbsp;&nbsp;SEND CERTIFICATE TO CANDIDATE
              </Dropdown.Item>
            </>
          )}
          {!cell.row.original.certificateId && (
            <>
              <Dropdown.Item
                onClick={() => {
                  downloadPreviewCertificate(
                    cell.row.original._id,
                    loggedUser.token,
                  );
                }}
                disabled={!cell.row.original.isCertificateEligible()}>
                <i className="nc-icon nc-zoom-split" />
                &nbsp;&nbsp;CERTIFICATE PREVIEW
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCurrentCell(cell);
                  setIsCertificateModalOpen(true);
                }}
                disabled={
                  !cell.row.original.isCertificateEligible() ||
                  loggedUser.isExamCentreAuditor() ||
                  loading
                }>
                <i className="nc-icon nc-paper" />
                &nbsp;&nbsp;GENERATE CERTIFICATE
              </Dropdown.Item>
            </>
          )}
          {loggedUser.hasAdminRole() && (
            <Dropdown.Item
            onClick={() => {
              getResult(cell.row.original.candidateId._id);
            }}>
            <i className="nc-icon nc-key-25" />
            &nbsp;&nbsp;VIEW MARKS
          </Dropdown.Item>)}
        </DropdownButton>
      ),
    },
  ];

  const onChangeSelection = useCallback((selectedRows: ExamGrade[]) => {
    setCertificatesToSend(selectedRows);
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h4 className="card-title">Candidate Results</h4>
        </CardHeader>
        <CardBody>
          {examGrades.grades && (
            <>
              <DropdownButton
                variant="default"
                title={'Send certificates'}
                disabled={!availableCertificatesToSend.length}>
                {!isSelectCertificatesMode && (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        setCertificatesToSend(
                          examGrades.grades.filter(
                            (grade: ExamGrade) =>
                              grade.certificateId && !grade.isCertificateSent,
                          ),
                        );
                        setIsSendCertificateModalOpen(true);
                      }}>
                      SEND ALL GENERATED CERTIFICATES
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setIsSelectCertificatesMode(true);
                      }}>
                      SELECT CERTIFICATES TO SEND
                    </Dropdown.Item>
                  </>
                )}
                {isSelectCertificatesMode && (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        setIsSendCertificateModalOpen(true);
                      }}
                      disabled={!certificatesToSend.length}>
                      SEND SELECTED CERTIFICATES
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setIsSelectCertificatesMode(false);
                      }}>
                      CANCEL SELECTION
                    </Dropdown.Item>
                  </>
                )}
              </DropdownButton>
              <ReactTable
                data={examGrades?.grades}
                columns={
                  isSelectCertificatesMode
                    ? [
                        {
                          id: 'selection',
                          Header: () => null,
                          Cell: (row: any) => (
                            <>
                              <Input
                                type="checkbox"
                                checked={row.row.isSelected}
                                onChange={() => {
                                  row.toggleRowSelected(
                                    row.row.id,
                                    !row.row.isSelected,
                                  );
                                }}
                                disabled={
                                  !row.row.original.certificateId ||
                                  row.row.original.isCertificateSent
                                }
                              />
                            </>
                          ),
                        },
                        ...columns,
                      ]
                    : columns
                }
                hasSelectRowColumn
                onChangeSelection={onChangeSelection}
              />
            </>
          )}
        </CardBody>
      </Card>
      {isCertificateModalOpen && (
        <ConfirmationModal
          title="Certificate confirmation"
          onConfirm={() => generate()}
          onCancel={() => setIsCertificateModalOpen(false)}
          text="Are you sure you want to generate this certificate? Check that the candidate data is correct before proceeding."
          confirmBtnText="Yes, generate"
          cancelBtnText="Cancel"
          children={
            <Button
              onClick={() => {
                downloadPreviewCertificate(
                  currentCell.row.original._id,
                  loggedUser.token,
                );
              }}>
              Certificate preview
            </Button>
          }
        />
      )}
      {isSendCertificateModalOpen && (
        <ConfirmationModal
          title="Sending certificates confirmation"
          onConfirm={() =>
            sendCertificates(
              certificatesToSend.reduce((result: string[], item: ExamGrade) => {
                return [...result, item.certificateId];
              }, []),
            )
          }
          onCancel={() => setIsSendCertificateModalOpen(false)}
          text="Check candidates who will receive their certificate by email:"
          confirmBtnText="Send"
          cancelBtnText="Cancel">
          <Table>
            <tbody>
              {certificatesToSend.map((item: ExamGrade) => (
                <tr key={item.certificateId}>
                  <td>{`${item.candidateId.firstName} ${item.candidateId.lastName}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ConfirmationModal>
      )}
      <Modal isOpen={modalPayment}>
        <ModalHeader>Certificate Reissue Fee</ModalHeader>
        <ModalBody>
          <NavigationExamPayComponent
            onCancel={() => setModalPayment(false)}
            onSuccess={() => reissueCertificate()}
            paymentType={PaymentTypeLabels.CERTIFICATE_REISSUE_FEE}
            reference={''}
            certificateId={currentCell.row?.original?.certificateId!}
          />
        </ModalBody>
      </Modal>
      <Marks
        open={Boolean(marksByCandidate)}
        results={marksByCandidate}
        onClose={() => setMarksByCandidate(null)}
      />
    </>
  );
};

export default CandidateResults;
